import axios from 'axios';
import { Role, RoleDetails } from '../../types/Role';

class RolesService {
  async getAll(): Promise<Role[]> {
    const result = await axios.get('/roles');
    return result.data;
  }
  async getDetails(): Promise<RoleDetails[]> {
    const result = await axios.get('/roles/details');
    return result.data;
  }
  async edit(data: RoleDetails, id: string): Promise<void> {
    return axios.put(`/roles/${id}`, data);
  }
}

let rolesService = new RolesService();
export default rolesService;
