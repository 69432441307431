import axios from 'axios';
import { CreateOrderRequest, Order } from '../../types/Order';
import { DateFilters, PaginatedResult, PaginationParams } from '../../types/general-types';

class OrdersService {
  async getAll(): Promise<Order[]> {
    const result = await axios.get('/orders');
    return result.data;
  }
  async search(paginationParams: PaginationParams, dateFilters: DateFilters): Promise<PaginatedResult<Order>> {
    const dataToSend: any = {
      paginationParams,
    };

    if (dateFilters) {
      dataToSend.dateFilters = dateFilters;
    }

    const result = await axios.post('/orders/search', dataToSend);
    return result.data;
  }
  async get(id: string): Promise<Order> {
    const result = await axios.get(`/orders/${id}`);
    return result.data;
  }
  async create(postData: CreateOrderRequest): Promise<void> {
    return axios.post(`/orders`, postData);
  }
  async resume(id: string): Promise<void> {
    return axios.post(`/orders/${id}/resume`);
  }
  async pause(id: string): Promise<void> {
    return axios.post(`/orders/${id}/pause`);
  }
  async stop(id: string): Promise<void> {
    return axios.post(`/orders/${id}/stop`);
  }
}

const ordersService = new OrdersService();
export default ordersService;
