import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from '../../types/Order';

import { Recipe } from '../../types/Recipe';
import { OrderIngredientTemplate } from './OrderIngredientItem';
import { format, parseISO } from 'date-fns';

interface FormValuesProps extends Order {}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  name: '',
  recipeId: null,
  status: '',
  startDate: '',
  endDate: '',
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  order: Order;

  availableRecipes: Recipe[];
};

function OrderDialog({
  dialogVisible,
  closeAddEditModal,
  order,

  availableRecipes,
}: Props) {
  const [formValues, setFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    closeAddEditModal();
  };

  const recipes = availableRecipes?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && order) {
      setFormValues({
        id: order.id,
        name: order.name,
        recipeId: order.recipeId,
        status: t(`orders.statuses.${order.status}`),
        startDate: format(parseISO(order.startDate), t('formats.full_format')),
        endDate: order.endDate && format(parseISO(order.endDate), t('formats.full_format')),
        ingredients: order.ingredients,
      });
    } else {
      setFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.close')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
    </>
  );

  return (
    <Dialog
      visible={dialogVisible}
      header={t('orders.order_details')}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={hideDialog}
      breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      style={{ width: '40vw' }}
    >
      <form>
        <div className="col-10">
          <div className="field">
            <label htmlFor="name">{t('common.name')}</label>
            <InputText id="name" value={formValues.name} readOnly={true} disabled={true} />
          </div>

          <div className="field">
            <label htmlFor="recipeId">{t('orders.recipe')}</label>
            <Dropdown
              id="recipeId"
              value={formValues.recipeId}
              disabled={true}
              options={recipes}
              placeholder={t('common.select')}
            />
          </div>

          <div className="field">
            <label htmlFor="status">{t('orders.status')}</label>
            <InputText id="status" value={formValues.status} readOnly={true} disabled={true} />
          </div>

          <div className="field">
            <label htmlFor="startDate">{t('orders.start_date')}</label>
            <InputText id="startDate" value={formValues.startDate} readOnly={true} disabled={true} />
          </div>

          <div className="field">
            <label htmlFor="endDate">{t('orders.end_date')}</label>
            <InputText id="endDate" value={formValues.endDate} readOnly={true} disabled={true} />
          </div>
          <div className="col-12 grid">
            {formValues.ingredients?.map((i, index) => OrderIngredientTemplate(i, index, t))}
          </div>
        </div>
      </form>
    </Dialog>
  );
}

export default OrderDialog;
