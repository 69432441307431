import axios from 'axios';
import { Ingredient } from '../../types/Ingredient';

class IngredientsService {
  async getAll(): Promise<Ingredient[]> {
    const result = await axios.get('/ingredients');
    return result.data;
  }
  async get(id: string): Promise<Ingredient> {
    const result = await axios.get(`/ingredients/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/ingredients/${id}`);
  }
  async create(postData: Ingredient): Promise<void> {
    return axios.post(`/ingredients`, mapToRequestBody(postData));
  }
  async edit(postData: Ingredient, id: string): Promise<void> {
    return axios.put(`/ingredients/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (ingredient: Ingredient): any => {
  const result = {
    id: ingredient.id,
    name: ingredient.name,
    pinNumber: ingredient.pinNumber,
    rawMaterialId: ingredient.rawMaterialId,
  };

  return result;
};

const ingredientsService = new IngredientsService();
export default ingredientsService;
