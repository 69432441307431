import { createSlice } from '@reduxjs/toolkit';

type Limits = {
  usersNumber?: number;
  scalesNumber?: number;
};

type UserContext = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  scales: any[];
  permissions: string[];
  roleId: string;
  active: boolean;
  activeUntil: Date;
  readonly: boolean;
  divisionIds: string[];
  customerId: string;
  limits: Limits;
};

type LoggedUser = {
  idToken: string;
  accessToken: string;
};

interface initialStateProps {
  context: UserContext;
  loggedUser: LoggedUser;
}

const initialUserContext: UserContext = {
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  scales: [],
  permissions: [],
  roleId: null,
  active: false,
  activeUntil: null,
  readonly: true,
  divisionIds: [],
  customerId: null,
  limits: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    context: initialUserContext,
    loggedUser: null,
  } as initialStateProps,
  reducers: {
    setLoggedUser(state, value) {
      state.loggedUser = value.payload;
    },
    setLoggedUserContext(state, value) {
      state.context = { ...initialUserContext, ...value.payload };
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
