import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import ioService from '../../service/api/IoService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Io, ioModels } from '../../types/Io';
import { modeContext } from '../../services/ModeContext';
import WriteOnlyButton from '../controls/WriteOnlyButton';

interface FormValuesProps extends Io {}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  name: '',
  description: '',
  hostId: null,
  model: 'E1210',
  ipAddress: '',
  isUsed: false,
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  io: Io;

  availableHosts: any[];

  showAddToast: (name: string) => void;
  showSaveToast: (name: string) => void;
};

function IoDialog({
  dialogVisible,
  closeAddEditModal,
  io,

  availableHosts,

  showAddToast,
  showSaveToast,
}: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const modeCtx = modeContext();

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const hosts = availableHosts?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const models = ioModels?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && io) {
      setInitFormValues({
        id: io.id,
        name: io.name,
        description: io.description,
        hostId: io.hostId,
        model: io.model,
        ipAddress: io.ipAddress,
        isUsed: io.isUsed,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }

      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      try {
        if (!formData.id) {
          await ioService.create(formData);

          showAddToast(formData.name);
        } else {
          await ioService.edit(formData, formData.id);

          showSaveToast(formData.name);
        }

        hideDialog();
      } catch (error: any) {
        if (error.response.status === 409) {
          const field = error.response.data.field || 'name';
          helpers.setFieldError(field, t(`io.error_${field}_already_taken`));
        }
      }
      helpers.setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
        disabled={formik.values.isUsed}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('io.io_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                disabled={formik.values.isUsed}
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="description">{t('io.description')}</label>
              <InputText
                id="description"
                disabled={formik.values.isUsed}
                value={formik.values.description}
                onChange={formik.handleChange}
                className={formik.touched.description && formik.errors.description && 'p-invalid'}
              />
              <FormErrorMessage fieldName="description" formikInstance={formik} />
            </div>
            {modeCtx.hostsEnabled && (
              <div className="field">
                <label htmlFor="hostId">{t('io.host')}</label>
                <Dropdown
                  id="hostId"
                  disabled={formik.values.isUsed}
                  value={formik.values.hostId}
                  onChange={formik.handleChange}
                  options={hosts}
                  placeholder={t('common.select')}
                  emptyMessage={t('io.hosts_empty_message')}
                  className={formik.touched.hostId && formik.errors.hostId && 'p-invalid'}
                />
                <FormErrorMessage fieldName="hostId" formikInstance={formik} />
              </div>
            )}
            <div className="field">
              <label htmlFor="model">{t('io.model')}</label>
              <Dropdown
                id="model"
                disabled={formik.values.isUsed}
                value={formik.values.model}
                onChange={formik.handleChange}
                options={models}
                placeholder={t('common.select')}
                className={formik.touched.model && formik.errors.model && 'p-invalid'}
              />
              <FormErrorMessage fieldName="model" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="ipAddress">{t('io.ip_address')}</label>
              <InputText
                id="ipAddress"
                disabled={formik.values.isUsed}
                value={formik.values.ipAddress}
                onChange={formik.handleChange}
                className={formik.touched.ipAddress && formik.errors.ipAddress && 'p-invalid'}
              />
              <FormErrorMessage fieldName="ipAddress" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default IoDialog;
