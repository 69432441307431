import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScalesList } from './ScalesList';
import { ScalesIndicationsProps } from './types';
import { Panel } from 'primereact/panel';

import generalStyle from '../../assets/custom-styles/general.module.scss';
import { Toast } from 'primereact/toast';
import { SETTINGS } from '../../enums/settings.enum';

export const ScalesIndications = (props: ScalesIndicationsProps) => {
  const { scales } = props;

  const toast = useRef<any>();
  const [itemsSelected, setItemsSelected] = useState<any>(scales);

  const { t } = useTranslation();

  const panelTemplate = (options: any) => {
    const className = `${options.className} justify-content-start`;
    const titleClassName = `${options.titleClassName} mr-2`;

    return (
      <div className={className}>
        <span className={titleClassName}>{options.props.header}</span>
      </div>
    );
  };

  const showToast = (summary: string, detail: string, severity: 'success' | 'info' | 'warn' | 'error') => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <Panel
          header={t('scales_indications.scale_indication')}
          className={generalStyle.panelTitle}
          headerTemplate={panelTemplate}
        >
          <ScalesList
            t={t}
            scales={scales}
            itemsSelected={itemsSelected}
            setItemsSelected={setItemsSelected}
            showToast={showToast}
          />
        </Panel>
      </div>

      <Toast ref={toast} />
    </div>
  );
};
