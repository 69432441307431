import { InputText } from 'primereact/inputtext';
import React from 'react';
import { OrderIngredient } from '../../types/OrderIngredient';

export function OrderIngredientTemplate(orderIngredient: OrderIngredient, index: number, t) {
  return (
    <React.Fragment key={index}>
      <div className="field col-6">
        <label htmlFor={`ingredients[${index}].ingredientId`}>{t('orderIngredients.ingredient')}</label>
        <InputText
          id={`ingredients[${index}].ingredientName`}
          value={orderIngredient.ingredientName}
          readOnly={true}
          disabled={true}
        />
      </div>

      <div className="field col-3">
        <label htmlFor={`ingredients[${index}].quantity`}>{t('orderIngredients.quantity')}</label>
        <InputText
          id={`ingredients[${index}].quantity`}
          value={
            orderIngredient.actualQuantity === orderIngredient.expectedQuantity
              ? orderIngredient.actualQuantity
              : `${orderIngredient.actualQuantity} / ${orderIngredient.expectedQuantity}`
          }
          readOnly={true}
          disabled={true}
        />
      </div>
    </React.Fragment>
  );
}
