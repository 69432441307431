import axios from 'axios';
import { GroupTemplate } from '../../types/GroupTemplate';
import { GroupTemplateGenerate } from '../../types/GroupTemplateGenerate';

class GroupTemplatesService {
  async getAll(): Promise<GroupTemplate[]> {
    const result = await axios.get('/group-templates');
    return result.data;
  }
  async get(id: string): Promise<GroupTemplate> {
    const result = await axios.get(`/group-templates/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/group-templates/${id}`);
  }
  async create(postData: GroupTemplate): Promise<void> {
    return axios.post(`/group-templates`, mapToRequestBody(postData));
  }
  async generate(postData: GroupTemplateGenerate): Promise<void> {
    return axios.post(`/group-templates/generate`, postData);
  }
  async edit(postData: GroupTemplate, id: string): Promise<void> {
    return axios.put(`/group-templates/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (groupTemplate: GroupTemplate): any => {
  const result = {
    id: groupTemplate.id,
    name: groupTemplate.name,
    connectors: groupTemplate.connectors,
  };

  return result;
};

const groupTemplatesService = new GroupTemplatesService();
export default groupTemplatesService;
