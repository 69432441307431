import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import installationService from '../../service/api/InstallationService';
import { Message } from 'primereact/message';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

interface VerifyLicenseKeyProps {
  onSuccess: () => void;
  onHide: () => void;
  dialogVisible: boolean;
}

export const VerifyLicenseKeyDialog = ({ onSuccess, onHide, dialogVisible }: VerifyLicenseKeyProps) => {
  const { t } = useTranslation();

  const [key, setKey] = useState<string | null>(null);
  const [keyError, setKeyError] = useState<string | null>(null);
  const [deviceKey, setDeviceKey] = useState<string | null>(null);
  const [isShowedDeviceKey, setIsShowedDeviceKey] = useState(false);
  const [isCopiedDeviceKey, setIsCopiedDeviceKey] = useState(false);

  const fetchDeviceKey = useCallback(() => {
    installationService.getDeviceKey().then((response) => {
      setDeviceKey(response.data);
    });
  }, []);

  const handleClickCopy = async (text: string, setIsCopied: (b: boolean) => void) => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  useEffect(() => {
    fetchDeviceKey();
  }, [fetchDeviceKey]);

  const onSubmit = useCallback(() => {
    key &&
      installationService
        .updateLicenseKey(key)
        .then((response) => {
          if (response.status === 201) {
            onSuccess ? onSuccess() : window.location.replace('/');
          }
        })
        .catch((e) => {
          setKeyError(e.response.data.error);
        });
  }, [key, setKeyError, onSuccess]);

  const dialogHeader = () => {
    return (
      <div className="grid">
        <div className="col-12">
          <div className="flex flex-column md:flex-row align-items-center p-4">
            <div className="flex-1 text-center">
              <img className="login-logo" src="assets/layout/images/logo-black.png" alt="" width="220" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getErrorMessage = () => {
    switch (keyError) {
      case 'EXPIRED_LICENSE':
        return t('verifyLicenseKeyPage.expiredLicenseError');
      case 'INVALID_SIGNATURE':
      case 'INVALID_LICENSE':
      default:
        return t('verifyLicenseKeyPage.invalidLicenseKeyError');
    }
  };

  return (
    <>
      <Dialog
        visible={dialogVisible}
        style={{ width: '600px' }}
        header={dialogHeader()}
        modal
        className="p-fluid"
        onHide={onHide}
        closable={!!onHide}
      >
        <div className="col-12 key">
          <label>{t('verifyLicenseKeyPage.header')}</label>
          <div className="key-input">
            <InputText type="key" onChange={(e: any) => setKey(e.target.value)} />
          </div>
          {keyError && (
            <div className="invalid-login-or-password-container mt--2vh ml--1vw">
              <Message severity="error" text={getErrorMessage()} />
            </div>
          )}
        </div>

        <div className="col-6 ml--1vw mt--4vh">
          <Button label={t('verifyLicenseKeyPage.verifyKey')} icon="pi pi-check" onClick={onSubmit} />
        </div>
        <div className="grid mt-5vh">
          <span className="field-checkbox md:col-12">
            <Checkbox
              id="showApiKey"
              checked={isShowedDeviceKey}
              onChange={() => setIsShowedDeviceKey((prev) => !prev)}
              disabled={!deviceKey}
            />
            <label htmlFor="showApiKey" className="checkbox-label ml-0.5vw">
              {t('verifyLicenseKeyPage.showInstallationKey')}
            </label>
          </span>
          {deviceKey && isShowedDeviceKey && (
            <div className="col-12 key mt--5vh">
              <label>{t('verifyLicenseKeyPage.deviceKey')}</label>
              <div className="login-input mb-2">
                <InputText type="key" value={deviceKey} />
                {isCopiedDeviceKey && (
                  <Message className="mt-0.1vw h-1.9vw mb--2vw" text={t('verifyLicenseKeyPage.dataCopied')} />
                )}
                <Button
                  className="w-8vw mt-2.1vw"
                  onClick={() => handleClickCopy(deviceKey, setIsCopiedDeviceKey)}
                  disabled={isCopiedDeviceKey}
                >
                  {t('verifyLicenseKeyPage.copy')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};
