import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useState } from 'react';
import scalesService from '../../../service/api/ScalesService';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { WEIGHING_UNITS } from '../../../enums/weighing-units.enum';
import { modeContext } from '../../../services/ModeContext';
import { CommunicationMode } from '../../../enums/communication-mode.enum';
import WriteOnlyButton from '../../controls/WriteOnlyButton';

const INIT_FORM_STATE: any = {
  id: null,
  hostId: null,
  divisionId: null,
  printerId: null,
  name: '',
  description: '',
  model: '',
  connectionType: '',
  communicationMode: '',
  ipAddress: '',
  port: null,
  portPath: '',
  baudRate: null,
  sendInterval: null,
  weighingUnit: '',
  measurementAccuracy: 0,
  measurementRegex: '',
  groupIds: [],
};

function ScaleDialog({
  dialogVisible,
  closeAddEditModal,
  scale,
  availableHosts,
  availableDivisions,
  availablePrinters,
  availableGroups,
  showAddToast,
  showSaveToast,
}) {
  const { t } = useTranslation();

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const modeCtx = modeContext();

  const hideDialog = () => {
    formik.resetForm(INIT_FORM_STATE);
    closeAddEditModal();
  };

  const hosts = availableHosts?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const divisions = availableDivisions?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const groups = availableGroups?.map((r) => {
    return { label: `${r.groupTemplateName ?? ''} - ${r.name}`, value: r.id };
  });

  const printers = availablePrinters
    ?.filter((r) => r.hostId === scale?.hostId)
    ?.map((r) => {
      return { label: r.name, value: r.id };
    });

  const models = [
    { label: 'AXIS', value: 'AXIS' },
    { label: 'Dini Argeo', value: 'Dini Argeo' },
    { label: 'Rinstrum', value: 'Rinstrum' },
    { label: 'XK3118T1', value: 'XK3118T1' },
  ];

  const connectionTypes = [
    { label: 'Ethernet', value: 'ETHERNET' },
    { label: 'RS-232', value: 'RS232' },
  ];

  const communicationModes = [
    { label: t('scales.enums.TRIGGERED_BY_US'), value: CommunicationMode.TRIGGERED_BY_US },
    { label: t('scales.enums.TRIGGERED_BY_SCALE'), value: CommunicationMode.TRIGGERED_BY_SCALE },
  ];

  const weighingUnits = [
    { label: t('common.kilograms'), value: WEIGHING_UNITS.KG },
    { label: t('common.grams'), value: WEIGHING_UNITS.G },
  ];

  useEffect(() => {
    if (dialogVisible && scale) {
      setInitFormValues({
        id: scale.id,
        hostId: scale.hostId,
        divisionId: scale.divisionId,
        printerId: scale.printerId,
        name: scale.name,
        description: scale.description,
        model: scale.model,
        connectionType: scale.connectionType,
        communicationMode: scale.communicationMode,
        ipAddress: scale.ipAddress,
        port: scale.port,
        portPath: scale.portPath,
        baudRate: scale.baudRate,
        sendInterval: scale.sendInterval,
        weighingUnit: scale.weighingUnit,
        measurementAccuracy: scale.measurementAccuracy,
        measurementRegex: scale.measurementRegex,
        groupIds: scale.groupIds,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data) => {
      let errors: any = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }
      if (!data.model?.trim()) {
        errors.model = t('scales.error_model_required');
      }
      if (!data.id && !data.weighingUnit?.trim()) {
        errors.weighingUnit = t('scales.error_weighing_unit_required');
      }
      if (!data.id && data.measurementAccuracy !== 0 && !data.measurementAccuracy) {
        errors.measurementAccuracy = t('scales.error_measurement_accuracy_required');
      }
      if (!data.sendInterval) {
        errors.sendInterval = t('scales.error_send_interval_required');
      }
      if (!data.connectionType?.trim()) {
        errors.connectionType = t('scales.error_connection_type_required');
      }
      if (!data.communicationMode?.trim()) {
        errors.communicationMode = t('scales.error_communication_mode_required');
      }
      if (data.ipAddress?.trim() && !validator.isIP(data.ipAddress)) {
        errors.ipAddress = t('scales.error_invalid_ip_address');
      }
      if (data.connectionType === 'ETHERNET') {
        if (!data.ipAddress?.trim()) {
          errors.ipAddress = t('scales.error_ip_address_required');
        }
        if (data.port === null) {
          errors.port = t('scales.error_port_required');
        }
      } else if (data.connectionType === 'RS232') {
        if (!data.portPath?.trim()) {
          errors.portPath = t('scales.error_port_path_required');
        }
        if (data.baudRate === null) {
          errors.baudRate = t('scales.error_baud_rate_required');
        }
      }
      if (!data.divisionId) {
        errors.divisionId = t('scales.error_division_required');
      }
      if (data.measurementRegex?.trim()) {
        const regex = new RegExp('\\(\\?<measurement>.*\\)', 'gi');
        if (!data.measurementRegex.match(regex)) {
          errors.measurementRegex = t('scales.error_measurement_regex');
        }
      }

      return errors;
    },
    onSubmit: async (formData, helpers) => {
      if (!formData.id) {
        scalesService
          .create(formData)
          .then(() => {
            hideDialog();
            showAddToast(formData.name);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              if (error.response.data.field === 'name') {
                helpers.setFieldError('name', t('scales.error_name_already_taken'));
              } else if (error.response.data.field === 'ipAddress') {
                helpers.setFieldError('ipAddress', t('scales.error_ip_address_already_taken'));
                helpers.setFieldError('port', t('scales.error_ip_address_already_taken'));
              } else if (error.response.data.field === 'portPath') {
                helpers.setFieldError('portPath', t('scales.error_port_path_already_taken'));
              }
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        scalesService
          .edit(formData, formData.id)
          .then(() => {
            hideDialog();
            showSaveToast(formData.name);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              if (error.response.data.field === 'name') {
                helpers.setFieldError('name', t('scales.error_name_already_taken'));
              } else if (error.response.data.field === 'ipAddress') {
                helpers.setFieldError('ipAddress', t('scales.error_ip_address_already_taken'));
                helpers.setFieldError('port', t('scales.error_ip_address_already_taken'));
              } else if (error.response.data.field === 'portPath') {
                helpers.setFieldError('portPath', t('scales.error_port_path_already_taken'));
              } else if (error.response.data.field === 'hostId') {
                helpers.setFieldError('hostId', t('scales.error_different_host'));
              }
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('scales.scale_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="groupIds">{t('scales.groups')}</label>
              <MultiSelect
                id="groupIds"
                value={formik.values.groupIds}
                options={groups}
                maxSelectedLabels={1}
                selectedItemsLabel={`${formik.values.groupIds.length} ${t('common.choosen_items')}`}
                onChange={formik.handleChange}
                placeholder={t('scales.select_groups')}
                className={formik.touched.groupIds && formik.errors.groupIds && 'p-invalid'}
              />
              <FormErrorMessage fieldName="groupIds" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="description">{t('scales.description')}</label>
              <InputText
                id="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                className={formik.touched.description && formik.errors.description && 'p-invalid'}
              />
              <FormErrorMessage fieldName="description" formikInstance={formik} />
            </div>

            {modeCtx.hostsEnabled && (
              <div className="field">
                <label htmlFor="hostId">{t('scales.host')}</label>
                <Dropdown
                  id="hostId"
                  value={formik.values.hostId}
                  onChange={formik.handleChange}
                  options={hosts}
                  placeholder={t('common.select')}
                  emptyMessage={t('scales.hosts_empty_message')}
                  showClear={true}
                  className={formik.touched.hostId && formik.errors.hostId && 'p-invalid'}
                />
                <FormErrorMessage fieldName="hostId" formikInstance={formik} />
              </div>
            )}

            <div className="field">
              <label htmlFor="divisionId">{t('scales.division')}</label>
              <Dropdown
                id="divisionId"
                value={formik.values.divisionId}
                onChange={formik.handleChange}
                options={divisions}
                placeholder={t('common.select')}
                className={formik.touched.divisionId && formik.errors.divisionId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="divisionId" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="printerId">{t('scales.printer')}</label>
              <Dropdown
                id="printerId"
                showClear
                value={formik.values.printerId}
                onChange={formik.handleChange}
                options={printers}
                placeholder={t('common.select')}
                className={formik.touched.printerId && formik.errors.printerId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="printerId" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="sendInterval">{t('scales.send_interval')}</label>
              <InputNumber
                id="sendInterval"
                value={formik.values.sendInterval}
                onValueChange={formik.handleChange}
                min={1}
                max={3600}
                className={formik.touched.sendInterval && formik.errors.sendInterval && 'p-invalid'}
              />
              <FormErrorMessage fieldName="sendInterval" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="weighingUnit">{t('scales.weighing_unit')}</label>
              <Dropdown
                id="weighingUnit"
                value={formik.values.weighingUnit}
                onChange={formik.handleChange}
                options={weighingUnits}
                placeholder={t('common.select')}
                disabled={initFormValues.id}
                className={formik.touched.weighingUnit && formik.errors.weighingUnit && 'p-invalid'}
              />
              <FormErrorMessage fieldName="weighingUnit" formikInstance={formik} />
            </div>

            {/* <div className="field">
                            <label htmlFor="measurementAccuracy">
                                {t("scales.measurement_accuracy")}
                            </label>
                            <InputNumber
                                id="measurementAccuracy"
                                value={formik.values.measurementAccuracy}
                                onValueChange={formik.handleChange}
                                disabled={initFormValues.id}
                                min={0}
                                max={8}
                                className={
                                    formik.touched.measurementAccuracy &&
                                    formik.errors.measurementAccuracy &&
                                    "p-invalid"
                                }
                            />
                            <FormErrorMessage
                                fieldName="measurementAccuracy"
                                formikInstance={formik}
                            />
                        </div> */}

            <div className="field">
              <label htmlFor="model">{t('scales.model')}</label>
              <Dropdown
                id="model"
                value={formik.values.model}
                onChange={formik.handleChange}
                options={models}
                placeholder={t('common.select')}
                className={formik.touched.model && formik.errors.model && 'p-invalid'}
              />
              <FormErrorMessage fieldName="model" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="measurementRegex">{t('scales.measurement_regex')}</label>
              <InputText
                id="measurementRegex"
                value={formik.values.measurementRegex}
                onChange={formik.handleChange}
                className={formik.touched.measurementRegex && formik.errors.measurementRegex && 'p-invalid'}
              />
              <FormErrorMessage fieldName="measurementRegex" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="communicationMode">{t('scales.communication_mode')}</label>

              <Dropdown
                id="communicationMode"
                value={formik.values.communicationMode}
                onChange={formik.handleChange}
                options={communicationModes}
                placeholder={t('common.select')}
                disabled={initFormValues.id}
                className={formik.touched.model && formik.errors.model && 'p-invalid'}
              />
              <FormErrorMessage fieldName="communicationMode" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="connectionType">{t('scales.connection_type')}</label>

              <Dropdown
                id="connectionType"
                value={formik.values.connectionType}
                onChange={formik.handleChange}
                options={connectionTypes}
                placeholder={t('common.select')}
                className={formik.touched.model && formik.errors.model && 'p-invalid'}
              />
              <FormErrorMessage fieldName="connectionType" formikInstance={formik} />
            </div>

            {formik.values.connectionType === 'ETHERNET' && (
              <>
                <div className="field">
                  <label htmlFor="ipAddress">{t('scales.ip_address')}</label>
                  <InputText
                    id="ipAddress"
                    value={formik.values.ipAddress}
                    onChange={formik.handleChange}
                    className={formik.touched.ipAddress && formik.errors.ipAddress && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="ipAddress" formikInstance={formik} />
                </div>

                <div className="field">
                  <label htmlFor="port">{t('scales.port')}</label>
                  <InputNumber
                    id="port"
                    value={formik.values.port}
                    onValueChange={formik.handleChange}
                    className={formik.touched.port && formik.errors.port && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="port" formikInstance={formik} />
                </div>
              </>
            )}

            {formik.values.connectionType === 'RS232' && (
              <>
                <div className="field">
                  <label htmlFor="portPath">{t('scales.port_path')}</label>
                  <InputText
                    id="portPath"
                    value={formik.values.portPath}
                    onChange={formik.handleChange}
                    className={formik.touched.portPath && formik.errors.portPath && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="portPath" formikInstance={formik} />
                </div>

                <div className="field">
                  <label htmlFor="baudRate">{t('scales.baud_rate')}</label>
                  <InputNumber
                    id="baudRate"
                    value={formik.values.baudRate}
                    onValueChange={formik.handleChange}
                    className={formik.touched.baudRate && formik.errors.baudRate && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="baudRate" formikInstance={formik} />
                </div>
              </>
            )}
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default ScaleDialog;
