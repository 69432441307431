import axios from 'axios';

class HostsService {
  get() {
    return axios.get('/hosts').then((res) => res.data);
  }
  getActive() {
    return axios.get('/hosts/active').then((res) => res.data);
  }
  delete(id) {
    return axios.delete(`/hosts/${id}`);
  }
  async create(postData) {
    return axios.post(`/hosts`, mapToRequestBody(postData));
  }
  async edit(postData, id) {
    return axios.put(`/hosts/${id}`, mapToRequestBody(postData));
  }

  async getActivationOtp(id) {
    const activationOtpResponse = await axios.get(`/hosts/${id}/otp`);

    return activationOtpResponse.data.otp;
  }
}

const mapToRequestBody = (host) => {
  const result = {
    id: host.id ? host.id : undefined,
    name: host.name,
    userId: host.userId,
    divisionId: host.divisionId,
    description: host.description,
    active: host.active,
  };

  return result;
};

let hostsService = new HostsService();
export default hostsService;
