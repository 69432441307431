import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import rawMaterialsService from '../../service/api/RawMaterialsService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { RawMaterial } from '../../types/RawMaterial';
import WriteOnlyButton from '../controls/WriteOnlyButton';

interface FormValuesProps extends RawMaterial {}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  name: '',
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  rawMaterial: RawMaterial;

  showAddToast: (name: string) => void;
  showSaveToast: (name: string) => void;
};

function RawMaterialDialog({
  dialogVisible,
  closeAddEditModal,
  rawMaterial,

  showAddToast,
  showSaveToast,
}: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && rawMaterial) {
      setInitFormValues({
        id: rawMaterial.id,
        name: rawMaterial.name,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }

      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      try {
        if (!formData.id) {
          await rawMaterialsService.create(formData);

          showAddToast(formData.name);
        } else {
          await rawMaterialsService.edit(formData, formData.id);

          showSaveToast(formData.name);
        }

        hideDialog();
      } catch (error: any) {
        if (error.response.status === 409) {
          const field = error.response.data.field || 'name';
          helpers.setFieldError(field, t(`rawMaterials.error_${field}_already_taken`));
        }
      }
      helpers.setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('rawMaterials.raw_material_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                value={formik.values.name}
                readOnly={rawMaterial ? true : undefined}
                disabled={rawMaterial ? true : undefined}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default RawMaterialDialog;
