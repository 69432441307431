import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import scalesService from '../../../service/api/ScalesService';
import hostsService from '../../../service/api/HostsService';
import { Dialog } from 'primereact/dialog';
import ScaleDialog from './ScaleDialog';
import { Trans, useTranslation } from 'react-i18next';
import { ScaleManagementProps } from './types';
import { Toast } from 'primereact/toast';
import { SETTINGS } from '../../../enums/settings.enum';
import divisionsService from '../../../service/api/DivisionsService';
import WriteOnlyButton from '../../controls/WriteOnlyButton';
import { Printer } from '../../../types/Printer';
import printersService from '../../../service/api/PrintersService';
import { RootState } from '../../../store';
import { hasPermission } from '../../_shared/hasPermission';
import groupsService from '../../../service/api/GroupsService';
import { Group } from '../../../types/Group';
import groupTemplatesService from '../../../service/api/GroupTemplatesService';

export const ScaleManagement = (props: ScaleManagementProps) => {
  const toast = useRef<any>();

  const { reloadMenu } = props;
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [scales, setScales] = useState(null);
  const [scaleToDelete, setScaleToDelete] = useState<any>();

  const [hosts, setHosts] = useState();

  const [divisions, setDivisions] = useState();

  const [printers, setPrinters] = useState<Printer[]>();

  const [groups, setGroups] = useState<Group[]>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const loggedUserContext = useSelector((state: RootState) => state.user.context);
  const { t } = useTranslation();

  const showSaveToast = (scaleName: string) => {
    toast.current.show({
      severity: 'success',
      summary: scaleName,
      detail: t('scales.toast_saved'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showAddToast = (scaleName: string) => {
    toast.current.show({
      severity: 'success',
      summary: scaleName,
      detail: t('scales.toast_added'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showDeleteToast = (scaleName: string) => {
    toast.current.show({
      severity: 'info',
      summary: scaleName,
      detail: t('scales.toast_deleted'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const loadData = () => {
    setLoading(true);
    Promise.all(
      [
        scalesService.get(),
        hostsService.getActive(),
        divisionsService.getAll(),
        hasPermission('PRINTER_MANAGEMENT', loggedUserContext.permissions) ? printersService.getAll() : null,
        groupsService.getAll(),
        groupTemplatesService.getAll(),
      ].filter(Boolean)
    ).then((response) => {
      setHosts(response[1]);
      setDivisions(response[2]);
      setPrinters(response[3]);

      const groupTemplatesDict = Object.assign({}, ...response[5].map((r: any) => ({ [r.id]: r.name })));
      const _groups = response[4];
      _groups.forEach((g) => {
        g.groupTemplateName = groupTemplatesDict[g.groupTemplateId];
      });
      setGroups(_groups);

      const hostsDict = Object.assign({}, ...response[1].map((r: any) => ({ [r.id]: r.name })));

      const divisionsDict = Object.assign({}, ...response[2].map((r: any) => ({ [r.id]: r.name })));

      const _scales = response[0];
      _scales.forEach((x) => {
        x.hostName = hostsDict[x.hostId];
        x.divisionName = divisionsDict[x.divisionId];
      });
      setScales(_scales);

      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addEnabled =
    loggedUserContext.limits?.scalesNumber == null || loggedUserContext.limits.scalesNumber > scales?.length;

  const scalesTableHeader = (
    <div className="table-header">
      {t('scales.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          disabled={!addEnabled}
          onClick={() => {
            addScale();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
    reloadMenu();
  };

  const confirmDeleteScale = (scale) => {
    setScaleToDelete(scale);
    setDeleteDialogVisible(true);
  };

  const deleteScale = async () => {
    setLoading(true);
    scalesService.delete(scaleToDelete.id).then(() => {
      loadData();
      showDeleteToast(scaleToDelete.name);

      reloadMenu();
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editScale = (_scale) => {
    setSelectedRowToEdit(_scale);
    setAddEditDialogVisible(true);
  };

  const addScale = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteScale()} />
    </>
  );

  const bodyTemplate = (data, props) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data) => (
    <span>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-success mr-2"
        onClick={() => {
          editScale(data);
        }}
      ></Button>
      <WriteOnlyButton
        type="button"
        icon="pi pi-trash"
        className="p-button-success"
        onClick={() => {
          confirmDeleteScale(data);
        }}
      />
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={scales}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('scales.empty_message')}
            loading={loading}
            header={scalesTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>
            <Column field="hostName" header={t('scales.host')} sortable body={bodyTemplate}></Column>
            <Column field="divisionName" header={t('scales.division')} sortable body={bodyTemplate}></Column>
            <Column field="description" header={t('scales.description')} sortable body={bodyTemplate}></Column>
            <Column field="model" header={t('scales.model')} sortable body={bodyTemplate}></Column>
            <Column field="connectionType" header={t('scales.connection_type')} sortable body={bodyTemplate}></Column>
            <Column field="ipAddress" header={t('scales.ip_address')} sortable body={bodyTemplate}></Column>
            <Column field="port" header={t('scales.port')} sortable body={bodyTemplate}></Column>
            <Column field="portPath" header={t('scales.port_path')} sortable body={bodyTemplate}></Column>
            <Column field="baudRate" header={t('scales.baud_rate')} sortable body={bodyTemplate}></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <ScaleDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            scale={selectedRowToEdit}
            availableHosts={hosts}
            availableDivisions={divisions}
            availablePrinters={printers}
            availableGroups={groups}
            showAddToast={showAddToast}
            showSaveToast={showSaveToast}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {scaleToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: scaleToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
};
