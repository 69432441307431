import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import usersService from '../../service/api/UsersService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { User } from '../../types/User';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Password } from 'primereact/password';
import { parseISO } from 'date-fns';
import { RootState } from '../../store';
import WriteOnlyButton from '../controls/WriteOnlyButton';
import { modeContext } from '../../services/ModeContext';

interface FormValuesProps extends User {
  confirmPassword?: string;
}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  email: '',
  firstName: '',
  lastName: '',
  roleId: '',
  password: '',
  confirmPassword: '',
  active: true,
  activeUntil: null,
  readonly: false,
};

function UserAdminDialog({ userDialogVisible, closeAddEditModal, user, availableRoles }) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const loggedUserContext = useSelector((state: RootState) => state.user.context);

  const modeCtx = modeContext();
  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const userRoles = availableRoles?.map((r) => {
    return { label: r.name, value: r.id };
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (userDialogVisible && user?.id) {
      setInitFormValues({
        id: user.id,
        email: user.email,
        firstName: user.firstName ? user.firstName : '',
        lastName: user.lastName ? user.lastName : '',
        roleId: user.roleId,
        active: user.active,
        activeUntil: parseISO(user.activeUntil),
        readonly: user.readonly,
        password: '',
        confirmPassword: '',
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data) => {
      let errors: any = {};

      if (modeCtx.adminSetPasswordEnabled && !data.id && !data.password?.trim()) {
        errors.password = t('register_dialog.empty_password_error');
      }

      if (data.password !== data.confirmPassword) {
        errors.confirmPassword = t('register_dialog.confirmed_password_error');
      }

      if (!data.roleId) {
        errors.roleId = t('users.error_role_required');
      }

      if (!data.email?.trim()) {
        errors.email = t('users.error_email_required');
      }

      if (!data.activeUntil) {
        errors.activeUntil = t('users.error_active_until_required');
      }

      return errors;
    },
    onSubmit: async (formData, helpers) => {
      try {
        if (!formData.id) {
          await usersService.create(formData);
        } else {
          await usersService.editById(formData, formData.id);
        }

        hideDialog();
      } catch (error: any) {
        if (error.response.status === 409) {
          const errorText: string = error.response.data.error;
          if (errorText?.toLowerCase()?.startsWith('invalid admin domain')) {
            helpers.setFieldError('email', t('users.error_invalid_admin_domain'));
          } else {
            helpers.setFieldError('email', t('users.error_email_already_taken'));
          }
        }
      }
      helpers.setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const userDialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={userDialogVisible}
        header={t('users.user_details')}
        modal
        className="p-fluid"
        footer={userDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="email">{t('users.email')}</label>
              {user?.id ? (
                <InputText id="email" value={formik.values.email} readOnly disabled />
              ) : (
                <InputText
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={formik.touched.email && formik.errors.email && 'p-invalid'}
                />
              )}
              <FormErrorMessage fieldName="email" formikInstance={formik} />
            </div>

            {modeCtx.adminSetPasswordEnabled && (
              <>
                <div className="field">
                  <label htmlFor="password">{t('users.password')}</label>
                  <Password
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    feedback={false}
                    toggleMask
                    readOnly={loggedUserContext.id === user?.id ? true : undefined}
                    disabled={loggedUserContext.id === user?.id ? true : undefined}
                    className={formik.touched.password && formik.errors.password && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="password" formikInstance={formik} />
                </div>
                <div className="field">
                  <label htmlFor="confirmPassword">{t('users.password_repeat')}</label>
                  <Password
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    feedback={false}
                    toggleMask
                    readOnly={loggedUserContext.id === user?.id ? true : undefined}
                    disabled={loggedUserContext.id === user?.id ? true : undefined}
                    className={formik.touched.confirmPassword && formik.errors.confirmPassword && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="confirmPassword" formikInstance={formik} />
                </div>
              </>
            )}

            <div className="field">
              <label htmlFor="firstName">{t('users.first_name')}</label>
              <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
            </div>
            <div className="field">
              <label htmlFor="lastName">{t('users.last_name')}</label>
              <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
            </div>

            <div className="field">
              <label htmlFor="roleId">{t('users.role')}</label>
              <Dropdown
                id="roleId"
                value={formik.values.roleId}
                onChange={formik.handleChange}
                options={userRoles}
                placeholder={t('common.select')}
                disabled={loggedUserContext.id === user?.id ? true : undefined}
                className={formik.touched.roleId && formik.errors.roleId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="roleId" formikInstance={formik} />
            </div>

            <div className="field-checkbox">
              <Checkbox
                id="active"
                checked={formik.values.active}
                onChange={formik.handleChange}
                readOnly={loggedUserContext.id === user?.id ? true : undefined}
                disabled={loggedUserContext.id === user?.id ? true : undefined}
              />
              <label htmlFor="active">{t('common.active')}</label>
            </div>

            <div className="field-checkbox">
              <Calendar
                id="activeUntil"
                placeholder={t('users.active_until')}
                locale="pl"
                value={formik.values.activeUntil}
                className="mr-2 col-12 px-0"
                onChange={formik.handleChange}
                showIcon
              />
              <FormErrorMessage fieldName="activeUntil" formikInstance={formik} />
            </div>

            <div className="field-checkbox">
              <Checkbox
                id="readonly"
                checked={formik.values.readonly}
                onChange={formik.handleChange}
                readOnly={loggedUserContext.id === user?.id ? true : undefined}
                disabled={loggedUserContext.id === user?.id ? true : undefined}
              />
              <label htmlFor="readonly">{t('users.readonly')}</label>
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default UserAdminDialog;
