import { useEffect, useState } from 'react';
import { DataView } from 'primereact/dataview';

import { Scale, ScalesListProps } from './types';
import { InputText } from 'primereact/inputtext';
import { ScalesListToolbar } from './ScalesListToolbar';
import scaleOrdersService from '../../service/api/ScaleOrdersService';
import weighingsService from '../../service/api/WeighingsService';
import { format, parseISO, toDate } from 'date-fns';
import { SETTINGS } from '../../enums/settings.enum';
import { CommunicationMode } from '../../enums/communication-mode.enum';
import WriteOnlyButton from '../controls/WriteOnlyButton';

const layout = 'list';

export const ScalesList = (props: ScalesListProps) => {
  const { t, scales, itemsSelected, setItemsSelected, showToast } = props;
  const [newItems, setNewItems] = useState([]);
  const [measurements, setMeasurements] = useState({});

  const defaultTag = null;

  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const scalesId = scales.map((element: any) => element.id);

    weighingsService.getLastIndications(scalesId).then((indications) => {
      let newMeasurements = {};

      for (const indication of indications) {
        newMeasurements[indication.scaleId] = {
          id: indication.id,
          scaleId: indication.scaleId,
          value: indication.value,
          date: format(parseISO(indication.measurementTimeLocal), SETTINGS.FULL_DATE_FORMAT),
        };
      }

      setMeasurements(newMeasurements);
    });
  }, [time, scales]);

  const getMeasurementDate = (scaleId: string) => {
    const date = measurements[scaleId]?.date;

    const formattedDate = date ? format(toDate(parseISO(date)), t('formats.full_format')) : '';
    return formattedDate;
  };

  const getMeasurementValue = (scaleId: string): string => {
    return measurements[scaleId]?.value || measurements[scaleId]?.value === 0
      ? measurements[scaleId]?.value.toString()
      : '';
  };

  const addTag = (e: any, scale: any) => {
    let outputArray: any = [];

    itemsSelected.forEach((element: Scale) => {
      if (element.id === scale.id) {
        outputArray.push({ ...element, tag: e.target.value });
      } else {
        outputArray.push(element);
      }
    });

    setNewItems(outputArray);
  };

  const getTag = (scale: Scale) => {
    let tag = defaultTag;

    newItems.forEach((element: Scale) => {
      if (element.id === scale.id) tag = element.tag;
    });

    return tag;
  };

  const getMeasurementId = (scale: Scale) => {
    return measurements[scale.id]?.id;
  };

  const handleSaveIndication = async (scale: Scale) => {
    const foundTag = getTag(scale);
    const foundMeasurementId = getMeasurementId(scale);

    const dataToSend = {
      rawMeasurementId: foundMeasurementId,
      tag: foundTag,
    };
    await weighingsService.saveScaleIndication(dataToSend);
    showToast(
      `${scale.name}: ${t('scales_indications.save_scale_indication')}`,
      t('scales_indications.toast_saved'),
      'success'
    );
  };

  const handleSaveIndicationAndPrint = async (scale: Scale) => {
    try {
      const foundTag = getTag(scale);
      const foundMeasurementId = getMeasurementId(scale);

      const dataToSend = {
        rawMeasurementId: foundMeasurementId,
        tag: foundTag,
      };
      await weighingsService.saveScaleIndicationAndPrint(dataToSend);
      showToast(
        `${scale.name}: ${t('scales_indications.save_scale_indication')}`,
        t('scales_indications.toast_saved_and_printed'),
        'success'
      );
    } catch (error: any) {
      if (error.response.status === 409) {
        const details = t(`printers.errors.${error.response.data.error}`);
        showToast(t('printers.error'), details, 'error');
      }
      if (error.response.status === 404) {
        const details = t(`printers.errors.NOT_FOUND`);
        showToast(t('printers.error'), details, 'error');
      }
    }
  };

  const executeCommand = async (command, scaleName) => {
    try {
      await command();
      showToast(`${scaleName}: ${t('scales_indications.success')}`, t('scales_indications.success_details'), 'success');
    } catch (error: any) {
      if (error.response.status === 409) {
        const details = t(`scales_indications.errors.${error.response.data.error}`);
        showToast(`${scaleName}: ${t('scales_indications.error')}`, details, 'error');
      }
    }
  };

  const zeroScale = async (scaleId: string, scaleName: string) => {
    showToast(`${scaleName}: ${t('scales_indications.reset')}`, t('scales_indications.toast_zero'), 'info');
    return await executeCommand(async () => {
      await scaleOrdersService.zero(scaleId);
    }, scaleName);
  };

  const tareScale = async (scaleId: string, scaleName: string) => {
    showToast(`${scaleName}: ${t('scales_indications.tare')}`, t('scales_indications.toast_tare'), 'info');
    return await executeCommand(async () => {
      await scaleOrdersService.tare(scaleId);
    }, scaleName);
  };

  const reconnectScale = async (scaleId: string, scaleName: string) => {
    showToast(`${scaleName}: ${t('scales_indications.reconnect')}`, t('scales_indications.toast_reconnect'), 'info');
    return await executeCommand(async () => {
      await scaleOrdersService.reconnect(scaleId);
    }, scaleName);
  };

  const parseMeasurementValueView = (scale: Scale) => {
    const measurementValue = getMeasurementValue(scale.id);

    const weighingUnit = scale.weighingUnit.toLowerCase();

    return measurementValue ? `${measurementValue} ${weighingUnit}` : '';
  };

  const dataViewListItem = (scale: Scale): JSX.Element | any => {
    return (
      <div key={scale?.id} className="col-12">
        <div className="flex flex-column md:flex-row align-items-center p-3 w-full">
          <div className="my-4 md:my-0 w-12 md:h-5rem md:w-12rem  mr-4">
            <div className={`font-bold text-2xl text-center md:text-center mt-3`}>
              {parseMeasurementValueView(scale)}
            </div>
          </div>

          <div className="flex-1 text-center md:text-left">
            <div className="font-bold text-2xl">{scale.name}</div>
            <div className="mt-2">
              <b>{t('scales_indications.scale_id')}</b>: {scale.id}
            </div>
            <div className="mb-3">
              <b>{t('scales_indications.date')}</b>: {getMeasurementDate(scale.id)}
            </div>
          </div>
          <div className="flex flex-row md:flex-row justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
            {scale.communicationMode === CommunicationMode.TRIGGERED_BY_SCALE && (
              <WriteOnlyButton
                id="reconnect"
                onClick={() => reconnectScale(scale.id, scale.name)}
                label={t('scales_indications.reconnect')}
                iconPos="right"
                className="p-button-raised p-button-primary mr-2 mb-2"
              />
            )}
            {scale.communicationMode === CommunicationMode.TRIGGERED_BY_US && (
              <>
                <WriteOnlyButton
                  id="button"
                  onClick={() => tareScale(scale.id, scale.name)}
                  label={t('scales_indications.tare')}
                  iconPos="right"
                  className="p-button-raised p-button-primary mr-2 mb-2"
                />

                <WriteOnlyButton
                  id="button"
                  onClick={() => zeroScale(scale.id, scale.name)}
                  label={t('scales_indications.reset')}
                  iconPos="right"
                  className="p-button-raised p-button-primary mr-2 mb-2"
                />

                <WriteOnlyButton
                  id="button"
                  onClick={() => handleSaveIndication(scale)}
                  label={t('scales_indications.save_scale_indication')}
                  iconPos="right"
                  disabled={getMeasurementValue(scale.id).length ? false : true}
                  className="p-button-raised p-button-primary mr-2 mb-2"
                />

                <WriteOnlyButton
                  id="button"
                  onClick={() => handleSaveIndicationAndPrint(scale)}
                  label={t('scales_indications.save_scale_indication_and_print')}
                  iconPos="right"
                  disabled={getMeasurementValue(scale.id).length ? false : true}
                  className="p-button-raised p-button-primary mr-2 mb-2"
                />

                <InputText
                  key={scale.id}
                  type="text"
                  placeholder={t('common.tag')}
                  className="mr-2 mb-2"
                  value={scale.tag}
                  disabled={getMeasurementValue(scale.id).length ? false : true}
                  onChange={(e) => addTag(e, scale)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="grid list-demo">
        <div className="col-12">
          <>
            <ScalesListToolbar
              t={t}
              scales={scales}
              itemsSelected={itemsSelected}
              setItemsSelected={setItemsSelected}
            />

            <DataView
              value={itemsSelected}
              layout={layout}
              itemTemplate={dataViewListItem}
              emptyMessage={t('scales_indications.empty_message')}
            ></DataView>
          </>
        </div>
      </div>
    </div>
  );
};
