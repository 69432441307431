import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import hostsService from '../../../service/api/HostsService';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import HostDialog from './HostDialog';
import { Trans, useTranslation } from 'react-i18next';
import { HostActivationDataDialog } from './HostActivationDataDialog';
import { Toast } from 'primereact/toast';
import { SETTINGS } from '../../../enums/settings.enum';
import divisionsService from '../../../service/api/DivisionsService';
import WriteOnlyButton from '../../controls/WriteOnlyButton';

export const HostManagement = () => {
  const toast = useRef<any>();

  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [activationOtp, setActivationOtp] = useState('');
  const [hosts, setHosts] = useState(null);
  const [hostToDelete, setHostToDelete] = useState<any>();

  const [divisions, setDivisions] = useState();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const [activationDialogVisible, setActivationDialogVisible] = useState(false);
  const { t } = useTranslation();

  const showSaveToast = (hostName: string) => {
    toast.current.show({
      severity: 'success',
      summary: hostName,
      detail: t('hosts.toast_saved'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showAddToast = (hostName: string) => {
    toast.current.show({
      severity: 'success',
      summary: hostName,
      detail: t('hosts.toast_added'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showDeleteToast = (hostName: string) => {
    toast.current.show({
      severity: 'info',
      summary: hostName,
      detail: t('hosts.toast_deleted'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const loadData = () => {
    setLoading(true);
    Promise.all([hostsService.get(), divisionsService.getAll()]).then((response) => {
      const _divisions = response[1];
      setDivisions(_divisions);

      const divisionsDict = Object.assign({}, ...response[1].map((r: any) => ({ [r.id]: r.name })));

      const _hosts = response[0];
      _hosts.forEach((x) => {
        x.divisionName = divisionsDict[x.divisionId];
      });
      setHosts(_hosts);

      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const hostsTableHeader = (
    <div className="table-header">
      {t('hosts.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addHost();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteHost = (host: any) => {
    setHostToDelete(host);
    setDeleteDialogVisible(true);
  };

  const deleteHost = () => {
    setLoading(true);
    hostsService.delete(hostToDelete.id).then(() => {
      loadData();
      showDeleteToast(hostToDelete.name);
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editHost = (_host) => {
    setSelectedRowToEdit(_host);
    setAddEditDialogVisible(true);
  };

  const activateHost = async (host) => {
    const otp = await hostsService.getActivationOtp(host.id);
    setActivationOtp(otp);
    setSelectedRowToEdit(host);
    setActivationDialogVisible(true);
  };

  const closeActivationDialog = () => {
    setActivationDialogVisible(false);
    loadData();
  };

  const addHost = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteHost()} />
    </>
  );

  const bodyTemplate = (data, props) => {
    return <>{data[props.field]}</>;
  };

  const boolColumnTemplate = (data, props, trueValue, falseValue) => {
    return (
      <React.Fragment>
        {data[props.field] === true ? (
          <Tag className="mr-2" icon="pi pi-check" severity="success" value={trueValue}></Tag>
        ) : (
          <Tag className="mr-2" icon="pi pi-times-circle" severity="danger" value={falseValue}></Tag>
        )}
      </React.Fragment>
    );
  };

  const actionTemplate = (data) => (
    <span>
      <WriteOnlyButton
        type="button"
        icon="pi pi-key"
        className="p-button-success mr-2"
        disabled={data.active}
        onClick={() => {
          activateHost(data);
        }}
      />
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-success mr-2"
        onClick={() => {
          editHost(data);
        }}
      />
      <WriteOnlyButton
        type="button"
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => {
          confirmDeleteHost(data);
        }}
      />
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={hosts}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('hosts.empty_message')}
            loading={loading}
            header={hostsTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>
            <Column field="description" header={t('hosts.description')} sortable body={bodyTemplate}></Column>
            <Column field="divisionName" header={t('hosts.division')} sortable body={bodyTemplate}></Column>
            <Column
              field="active"
              header={t('hosts.active')}
              sortable
              body={(data, props) => boolColumnTemplate(data, props, t('hosts.active'), t('hosts.inactive'))}
            ></Column>
            <Column
              headerStyle={{ width: '12rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <HostDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            host={selectedRowToEdit}
            availableDivisions={divisions}
            showAddToast={showAddToast}
            showSaveToast={showSaveToast}
          />
          <HostActivationDataDialog
            dialogVisible={activationDialogVisible}
            closeDialog={closeActivationDialog}
            host={selectedRowToEdit}
            activationOtp={activationOtp}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {hostToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: hostToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
};
