import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import generalStyle from '../../assets/custom-styles/general.module.scss';
import { LoginPanelProps } from './types';
import { useState, useRef } from 'react';
import { modeContext } from '../../services/ModeContext';
import validator from 'validator';

export const LoginPanel = (props: LoginPanelProps) => {
  const {
    t,
    openPasswordRecoveryPanel,
    invalidCredentials,
    otherError,
    openRegisterPanel,
    dialogHeader,
    setInvalidCredentials,
    onSuccess,
    setLoginPassword,
    setLoginEmail,
  } = props;

  const modeCtx = modeContext();

  const [emptyEmail, setEmptyEmail] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emptyPassword, setEmptyPassword] = useState(false);

  const emailInputRef = useRef<any>();
  const passwordInputRef = useRef<any>();

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    const isEmailValid = validateEmail(enteredEmail);
    const isPasswordValid = validatePassword(enteredPassword);

    if (!isEmailValid || !isPasswordValid) {
      setInvalidCredentials(false);
      return;
    }

    setLoginPassword(enteredPassword);
    setLoginEmail(enteredEmail);

    await onSuccess(enteredEmail, enteredPassword);
  };

  const validateEmail = (email: string): boolean => {
    if (!email) {
      setInvalidEmail(false);
      setEmptyEmail(true);
      return false;
    }

    if (!validator.isEmail(email)) {
      setEmptyEmail(false);
      setInvalidEmail(true);
      return false;
    }

    setEmptyEmail(false);
    setInvalidEmail(false);
    return true;
  };

  const validatePassword = (password: string): boolean => {
    if (!password) {
      setEmptyPassword(true);
      return false;
    }

    setEmptyPassword(false);
    return true;
  };

  return (
    <>
      <Dialog
        visible={true}
        style={{ width: '600px' }}
        header={dialogHeader(t('login_page.subheader'))}
        modal
        className="p-fluid"
        onHide={() => null}
        closable={false}
      >
        <form onSubmit={submitHandler}>
          <div className="col-12 md:col-11 mb-3 ml-auto mr-auto">
            <div className={`field ${emptyEmail ? 'mb-0' : 'mb-2'}`}>
              <label htmlFor="email">
                <b>{t('common.email')}</b>
              </label>
              <span className="p-input-icon-left">
                <i className="pi pi-user" />
                <InputText placeholder={t('common.email')} type="text" ref={emailInputRef} />
              </span>
            </div>

            <div className="mb-3">
              {emptyEmail && <Message severity="error" text={t('login_page.empty_email_error')} />}
            </div>

            <div className="mb-3">
              {invalidEmail && <Message severity="error" text={t('login_page.invalid_email_error')} />}
            </div>

            <div className={`field  ${emptyPassword ? 'mb-0' : 'mb-2'}`}>
              <label htmlFor="password">
                <b>{t('login_page.password')}</b>
              </label>
              <span className="p-input-icon-left">
                <i className="pi pi-lock" />
                <InputText placeholder={t('login_page.password')} type="password" ref={passwordInputRef} />
              </span>
            </div>

            <div className="mb-1">
              {emptyPassword && <Message severity="error" text={t('login_page.password_error')} />}
            </div>

            <div className="flex mb-7">
              {modeCtx.passwordRecoveryEnabled && (
                <div className="ml-auto">
                  <Button
                    style={{ width: '170px' }}
                    className={`p-button-link text-right  mt-2 pr-1`}
                    label={t('login_page.forgot_password')}
                    type="button"
                    onClick={openPasswordRecoveryPanel}
                  />
                </div>
              )}
            </div>

            <div className="field mb-1">
              <Button className={`mb-0 ${generalStyle.largeButton}`} label={t('login_page.login')} type="submit" />
            </div>

            {invalidCredentials && (
              <div className="mb-1">
                <Message severity="error" text={t('login_page.invalid_credentials_error')} />
              </div>
            )}

            {otherError && (
              <div className="mb-1">
                <Message severity="error" text={t('login_page.other_error')} />
              </div>
            )}

            {modeCtx.registerEnabled && (
              <div className="flex flex-1">
                <p className="mt-4">{t('login_page.no_account')}</p>
                <Button
                  style={{ width: '140px' }}
                  className={`p-button-link text-left font-bold mt-2 pl-2`}
                  label={t('login_page.register')}
                  type="button"
                  onClick={openRegisterPanel}
                />
              </div>
            )}
          </div>
        </form>
      </Dialog>
    </>
  );
};
